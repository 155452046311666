// 引入全局样式
import * as vConsole from 'vconsole'
import '@/assets/scss/index.scss'
// 时间格式化插件
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

// vant组件
import vantIndex from './vant'
import {getTimer} from './utils/timer'
import TraceLog, {debounce} from './utils/traceLog'

// 手机端控制台
if (process.env.NODE_ENV !== 'production') {
    new vConsole()
}

export default app => {
    vantIndex(app)
    app.config.globalProperties.$dayjs = dayjs //时间格式化插件
    app.config.globalProperties.$getTimer = getTimer // 时间处理
    app.config.globalProperties.$traceLog = TraceLog
    app.directive('trace-click', {
        mounted(el, binding) {
            //按钮点击执行事件
            const handleClick = (el, binding) => {
                //绑定值
                const data = binding.value
                //埋点外部传参对象
                const dynamicInfo = {...data}
                TraceLog.pointAdd(dynamicInfo, el)
            }
            const wrappedClickEvent = function (event) {
                handleClick(el, binding, event)
            }
            el.addEventListener('click', debounce(wrappedClickEvent, 300))
            el.handleClick = handleClick
        },
        unmounted(el, binding) {
            const handleClick = el.handleClick
            el.removeEventListener('click', handleClick)
            delete el.handleClick
        },
    })
    app.directive('trace-blur', {
        mounted(el, binding) {
            const handleBlur = (el, binding) => {
                const data = binding.value
                const dynamicInfo = {...data}
                TraceLog.pointAdd(dynamicInfo, el)
            }
            const wrappedBlurEvent = function (event) {
                handleBlur(el, binding, event)
            }
            el.addEventListener('change', debounce(wrappedBlurEvent, 300))
            el.handleBlur = handleBlur
        },
        unmounted(el, binding) {
            const handleClick = el.handleClick
            el.removeEventListener('click', handleClick)
            delete el.handleClick
        },
    })
    app.directive('trace-init', {
        mounted(el, binding) {
            const data = binding.value
            const dynamicInfo = {...data}
            TraceLog.pointAdd(dynamicInfo, el)
        }
    })
}
